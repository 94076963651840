<template>
  <div
    class="relative flex flex-row divide-x divide-solid divide-gray-200 rounded-lg bg-white py-4 px-7 transition-all duration-300 md:py-7"
    :class="{
      'border-3 border-transparent hover:rounded-2xl hover:border-blue-500 hover:green:border-green-500 hover:pink:border-pink-500 hover:blue-el:border-blue-500 hover:green-el:border-green-500 hover:pink-el:border-pink-500':
        card.event_card_layout.value === 'as_link',
    }"
  >
    <!-- DATE -->
    <div
      class="flex h-36 w-20 shrink-0 flex-col justify-center pr-5 md:h-44 md:w-36 lg:w-48"
    >
      <span
        class="block text-center text-xl font-semibold md:text-2xl lg:text-5xl"
        >{{ day }}</span
      >
      <span
        class="relative z-40 hidden text-center text-2xs font-semibold uppercase !tracking-wider transition-colors group-open:text-white md:block md:text-md"
        >{{ monthLong }}</span
      >
      <span
        class="relative z-40 block text-center text-2xs font-semibold uppercase leading-snug !tracking-wider transition-colors group-open:text-white md:hidden md:text-md"
        >{{ monthShort }}</span
      >
    </div>
    <!-- TEXT AND TITLE -->
    <div class="overflow-wrap-anywhere pl-4 lg:pl-16">
      <h3
        class="mb-1 whitespace-pre-line text-base leading-[1.18] md:text-lg lg:text-2xl"
      >
        {{ card.title }}
      </h3>
      <div
        class="text-3xs font-semibold leading-tight !tracking-normal opacity-60 sm:pr-6 md:text-2xs"
      >
        <span v-if="card.location" class="font-normal tracking-widest"
          >Ort </span
        >{{ card.location }}
        <div v-if="card.time" class="mt-2 leading-none md:mt-0">
          <span class="font-normal tracking-widest">Zeit </span>{{ card.time }}
        </div>
      </div>
      <NuxtLink
        v-if="card.event_card_layout.value === 'as_link'"
        :to="card.url"
        :aria-label="`Link zur Seite: ${card.title}`"
        class="text-theme group mt-7 inline-block text-2xs !leading-none !ring-0 before:absolute before:inset-0 md:text-xs"
      >
        <AppLinkWithIcon is="span"> Mehr erfahren </AppLinkWithIcon>
      </NuxtLink>
    </div>
  </div>
</template>

<script setup>
import { useDateFormat } from '@vueuse/core'
const props = defineProps({
  card: { type: Object, required: true },
})

let day = useDateFormat(props.card?.event_date, 'DD')
let monthLong = useDateFormat(props.card?.event_date, 'MMMM', {
  locales: 'de',
})
let monthShort = useDateFormat(props.card?.event_date, 'MMM', {
  locales: 'de',
})
</script>
